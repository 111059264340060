

#formLogin > div:first-of-type {
    /*margin-bottom: 24px;*/
}

#formLogin input {
    text-align: center;
}



.facebookLogin {
    background-color: rgb(66, 103, 178);
    color: rgb(255, 255, 255);
    font-size: 14px;
    padding: 12px 24px;
    border: none;

    width: 100%;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.facebookLogin:disabled {
    opacity: 0.5;
}

.facebookLogin i {
    margin-right: 10px;
    position: absolute;
    left: 40px;
    top: 12px;
    width: 20px;
    height: 20px;
    background-color: white;
    color: rgb(66, 103, 178);
    border-radius: 4px;
}

.facebookLogin i::before {
    position: relative;
    bottom: -3px;
}





.googleLogin {
    background-color: white;
    color: black;
    font-size: 14px;
    padding: 12px 24px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.15);

    width: 100%;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.googleLogin:disabled {
    opacity: 0.5;
}

.googleLogin img {
    margin-right: 10px;
    position: absolute;
    left: 37px;
    top: 10px;
    width: 24px;
    height: 24px;
    background-color: white;
    color: rgb(66, 103, 178);
    border-radius: 4px;
}

.googleLogin i::before {
    position: relative;
    bottom: -3px;
}